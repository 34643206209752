import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取ICON列表
 * @returns
 */
export const iconList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/icon/list`,
      params: params,
      method: 'get'
    })
  )
}

