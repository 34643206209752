<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :xs="7" :sm="3" :md="2" :lg="2" :xl="2" class="flex">
          <el-button type="primary" @click="onAdd">添加</el-button>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="5" class="flex">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="categoryId" label="类别编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="类别名称" align="center"></el-table-column>
      <el-table-column label="ICON图片" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.iconImg" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="level" label="分类等级" align="center">
        <template slot-scope="scope">
          {{ scope.row.level == 1 ? '一级分类' : '二级分类' }}
        </template>
      </el-table-column>
      <el-table-column prop="minaHomeIconSortNum" label="排序" align="center">
        <template slot-scope="scope">
          {{ scope.row.minaHomeIconSortNum }}
          <i @click="onEidtPaixu(scope.row)" class="el-icon-edit-outline font18 margin-left10 color9 pinter"></i>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="lastUpdateTime" label="更新时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.lastUpdateTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.isHide == 1" size="mini" type="warning">已隐藏</el-button>
          <el-button v-else size="mini" type="primary">已显示</el-button>
        </template>
      </el-table-column> -->
      <el-table-column prop label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="onDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>

    <!-- 添加  -->
    <el-dialog title="添加" v-if="addVisible" :visible.sync="addVisible" width="550px">
      <div class="margin-right-10">
        <el-form label-position="right" label-width="80px">
          <el-form-item label="选择">
            <el-cascader v-model="addValue" :props="props" clearable></el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer margin-right-10">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddOk">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改排序  -->
    <el-dialog title="修改排序" v-if="editVisible" :visible.sync="editVisible" width="550px">
      <div class="margin-right-10">
        <el-form label-position="right" label-width="80px">
          <el-form-item label="排序">
            <el-input v-model="num" v-Int></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer margin-right-10">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdatePaixu">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { iconList, iconDelete } from '@/api/IconController'
import { categoryList, categorySecondList, categoryUpdate } from '@/api/GoodsController'
export default {
  data() {
    return {
      params: {
        keyWords: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      tableList: [],

      editVisible: false, // 修改弹框
      dtaTaAs: {
        categoryId: ''
      },
      num: '', //修改排序

      addVisible: false, // 添加弹框
      addValue: '',

      props: {
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { level } = node
          console.log(node)
          if (node.root) {
            let [err, res] = await categoryList()
            if (err) {
              console.log(err)
              return this.$message.error(err.msg || '获取失败')
            }
            console.log(res)

            let list = res.data || []
            const nodes = list.map(item => ({
              value: item,
              label: item.name,
              leaf: !item.hadSecondCategory
            }))
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes)
          } else {
            if (!node.hasChildren) return resolve(nodes)
            let [err, res] = await categorySecondList({ categoryId: node.value.categoryId })
            if (err) {
              console.log(err)
              return this.$message.error(err.msg || '获取失败')
            }
            console.log(res)

            let list = res.data || []
            const nodes = list.map(item => ({
              value: item,
              label: item.name,
              leaf: true
            }))
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes)
          }
        }
      }
    }
  },

  mounted() {
    this.fecthList()
  },

  methods: {
    changeParams(v) {
      !v && this.handleSearch()
    },

    handleSearch() {
      console.log(this.params)
      this.fecthList()
    },

    async fecthList() {
      let [err, res] = await iconList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },

    //修改排序弹出
    onEidtPaixu(row) {
      this.dtaTaAs = { ...row }
      this.num = row.minaHomeIconSortNum
      this.editVisible = true
    },

    // 更新排序
    async onUpdatePaixu() {
      let num = this.num
      if (num != '') {
        if (num - this.dtaTaAs.minaHomeIconSortNum == 0) {
          return this.$message.error('修改前后排序数值一样')
        }
        let param = { ...this.dtaTaAs }
        param.minaHomeIconSortNum = num
        this.onUpdate(param)
        this.editVisible = false
      } else {
        this.$message.error('请填写库存数量')
      }
    },

    //新建
    onAdd() {
      this.addValue = ''
      this.addVisible = true
    },

    //确定添加
    onAddOk() {
      if (this.addValue) {
        console.log(this.addValue)
        let param = { ...this.addValue[this.addValue.length - 1] }
        param.isMinaHomeIcon = 1
        this.onUpdate(param)
        this.addVisible = false
      } else {
        this.$message.error('没有选中的值')
      }
    },

    //更新
    async onUpdate(param) {
      let [err, res] = await categoryUpdate(param)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.fecthList()
    },

    //删除
    onDelete(row) {
      this.$confirm(`确定要删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let param = { ...row }
          param.isMinaHomeIcon = 0
          this.onUpdate(param)
        })
        .catch(() => {})
    }
  },

  components: {
    TitleBarBaseVue
  }
}
</script>
